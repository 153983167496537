import React, { memo, useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { styled, css } from '@compiled/react';
import ButtonLegacy from '@atlaskit/button';
import Button, { IconButton } from '@atlaskit/button/new';
import ChevronDownOld from '@atlaskit/icon/glyph/chevron-down';
import ChevronDown from '@atlaskit/icon/utility/migration/chevron-down';
import { xcss, Text, Flex } from '@atlaskit/primitives';
import type { OptionsOrGroups, GroupBase } from '@atlaskit/react-select/src/types.tsx';
import { type OptionProps, components, PopupSelect, type GroupProps } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import type { BusinessIssueType } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import {
	ExperienceErrorBoundary,
	useExperienceStart,
	useExperienceSuccess,
	useExperienceFail,
	useExperienceAbort,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import { getIssueTypesSettingsUrl } from '@atlassian/jira-business-issue-types/src/index.tsx';
import {
	CREATE_TYPE_ID_PREFERENCE,
	FILTER_PREFERENCE,
	SORTING_PREFERENCE,
} from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import { fontFamily, fontSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { MAX_SUMMARY_LENGTH } from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import { CREATE_ISSUE_EXPERIENCE } from '../../constants.tsx';
import { CancelledError } from '../../controllers/create-issue-dialog/index.tsx';
import { useCreateIssue } from '../../controllers/issue-create-context/index.tsx';
import type {
	AdditionalCreateIssueContext,
	CreateIssueReturn,
	InlineCreateStyles,
	Location,
} from '../../types.tsx';
import messages from './messages.tsx';
import ReturnIcon from './return-icon/index.tsx';

const GENERIC_ICON_URL = '/icons/issuetypes/genericissue.svg';

export const CARD_APPEARANCE = 'CARD' as const;
export const ROW_APPEARANCE = 'ROW' as const;

const LINK = 'link';
const ISSUE_TYPE = 'issueType';
const BUTTON = 'button';

type InlineCreateAppearance = typeof CARD_APPEARANCE | typeof ROW_APPEARANCE;

type ExperienceId = 'board' | 'list' | 'calendar' | 'timeline';

export type Props<TIssue> = InlineCreateStyles & {
	allowedIssueTypeIds?: string[];
	appearance: InlineCreateAppearance;
	fieldIdsToReturn?: string[];
	/**
	 * @deprecated
	 * this prop is no longer required after visual-refresh, remove when cleaning up
	 */
	isCompact?: boolean;
	location: Location;
	maxHierarchyLevel?: number;
	minHierarchyLevel?: number;
	onBlur?: (event?: React.KeyboardEvent | React.MouseEvent) => void;
	onPrepareIssueContext?: () => AdditionalCreateIssueContext;
	onSubmit: (result: CreateIssueReturn<TIssue>) => void;
	popperStrategy?: 'absolute' | 'fixed';
};

type PropsWithIssueTypes<TIssue> = Props<TIssue> & {
	issueTypes: BusinessIssueType[];
};

type IssueTypeOption = {
	label: string;
	value: string;
	issueType: BusinessIssueType;
	optionType: typeof ISSUE_TYPE;
};

type LinkOption = {
	label: string;
	value: string;
	url: string;
	optionType: typeof LINK;
};

type ButtonOption = {
	label: string;
	optionType: typeof BUTTON;
};

type SelectOption = IssueTypeOption | LinkOption | ButtonOption;

const useExperience = (location: Location): ExperienceDetails =>
	useMemo(() => {
		let experienceId: ExperienceId;

		switch (location) {
			case 'listFooter':
			case 'listSibling':
			case 'listGroup':
			case 'listHierarchy':
				experienceId = 'list';
				break;
			case 'boardFooter':
			case 'boardSibling':
				experienceId = 'board';
				break;
			case 'calendarDate':
				experienceId = 'calendar';
				break;
			case 'timelineFooter':
			case 'timelineSibling':
			case 'timelineHierarchy':
				experienceId = 'timeline';
				break;
			default:
				throw new Error(`Unknown location ${location}`);
		}

		return {
			...CREATE_ISSUE_EXPERIENCE,
			experienceId,
		};
	}, [location]);

const Option = ({ children, ...props }: OptionProps<SelectOption>) => {
	if (props.data.optionType === ISSUE_TYPE) {
		return (
			<components.Option {...props}>
				<OptionWrapper data-testid="business-issue-create.ui.inline-create-form.option">
					<OptionTypeIcon src={props.data.issueType.avatarUrl ?? GENERIC_ICON_URL} alt="" />
					{children}
				</OptionWrapper>
			</components.Option>
		);
	}

	if (props.data.optionType === BUTTON) {
		return <components.Option {...props}>{children}</components.Option>;
	}

	return (
		<StyledLink href={props.data.url}>
			<components.Option {...props}>{children}</components.Option>
		</StyledLink>
	);
};

const Group = ({ children }: GroupProps<SelectOption>) => {
	return <ExtraLinksGroup>{children}</ExtraLinksGroup>;
};

const OptionOld = ({ children, ...props }: OptionProps<SelectOption>) => {
	if (props.data.optionType === ISSUE_TYPE) {
		return (
			<components.Option {...props}>
				<OptionWrapper data-testid="business-issue-create.ui.inline-create-form.option">
					<OptionTypeIcon src={props.data.issueType.avatarUrl ?? GENERIC_ICON_URL} alt="" />
					{children}
				</OptionWrapper>
			</components.Option>
		);
	}

	return (
		<ExtraLinksGroup>
			{/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
			<StyledLink href={(props.data as LinkOption).url}>
				<components.Option {...props}>{children}</components.Option>
			</StyledLink>
		</ExtraLinksGroup>
	);
};

export const useIsReady = () => {
	const isReadyRef = useRef(false);
	useEffect(() => {
		setTimeout(() => {
			isReadyRef.current = true;
		}, 100);
	}, []);

	return isReadyRef;
};

const isComposing = (event: React.KeyboardEvent) => event.nativeEvent.isComposing;

const ChevronDownIcon = () => {
	if (isVisualRefreshEnabled()) {
		return <ChevronDown label="" color={token('color.icon')} />;
	}
	return <ChevronDownOld label="" />;
};

const InlineCreateForm = <TIssue,>({
	allowedIssueTypeIds,
	appearance,
	borderRadius,
	fieldIdsToReturn = [],
	indent,
	isCompact,
	location,
	maxHierarchyLevel = Number.POSITIVE_INFINITY,
	minHeight,
	minHierarchyLevel = 0,
	onBlur,
	onPrepareIssueContext,
	onSubmit,
	popperStrategy = 'absolute',
}: Props<TIssue>) => {
	const { formatMessage } = useIntl();
	const {
		data: { issueTypes },
		loading,
	} = useIssueTypesAndFields({ issueOperation: 'CREATE' });

	if (loading) {
		return (
			<FullContent
				appearance={appearance}
				borderRadius={borderRadius}
				indent={indent}
				minHeight={minHeight}
			>
				<Flex alignItems="center" justifyContent="center" xcss={spinnerWrapperStyles}>
					<Spinner label={formatMessage(messages.loadingIssueCreateForm)} />
				</Flex>
			</FullContent>
		);
	}

	// this is not a scenario we expect to happen but we know it does happen when a project is just created
	// the issue types and fields have a retry mechanism to avoid this but we want to handle it here as well
	// to avoid runtime errors
	if (issueTypes.length === 0) {
		throw new Error('Unable to show the issue create form, no issue types available');
	}

	return (
		<InlineCreateFormInternal
			allowedIssueTypeIds={allowedIssueTypeIds}
			appearance={appearance}
			borderRadius={borderRadius}
			fieldIdsToReturn={fieldIdsToReturn}
			indent={indent}
			isCompact={isCompact}
			issueTypes={issueTypes}
			location={location}
			maxHierarchyLevel={maxHierarchyLevel}
			minHeight={minHeight}
			minHierarchyLevel={minHierarchyLevel}
			onBlur={onBlur}
			onPrepareIssueContext={onPrepareIssueContext}
			onSubmit={onSubmit}
			popperStrategy={popperStrategy}
		/>
	);
};

const InlineCreateFormInternal = <TIssue,>({
	allowedIssueTypeIds,
	appearance,
	borderRadius,
	fieldIdsToReturn = [],
	indent,
	isCompact,
	issueTypes: issueTypesProp,
	location,
	maxHierarchyLevel = Number.POSITIVE_INFINITY,
	minHeight,
	minHierarchyLevel = 0,
	onBlur,
	onPrepareIssueContext,
	onSubmit,
	popperStrategy = 'absolute',
}: PropsWithIssueTypes<TIssue>) => {
	const { formatMessage } = useIntl();
	const issueTypes = fg('fun-1256_handle_available_fields_load_in_ic_2')
		? issueTypesProp
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useIssueTypesAndFields({ issueOperation: 'CREATE' }).data.issueTypes;
	const project = useProject();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [filter] = useViewPreference(FILTER_PREFERENCE);
	const [sorting] = useViewPreference(SORTING_PREFERENCE);
	const [preferredIssueTypeId, savePreferredIssueTypeId] =
		useViewPreference(CREATE_TYPE_ID_PREFERENCE);
	const showSuccessFlag = filter != null || sorting != null;

	const experience = useExperience(location);
	const startExperience = useExperienceStart(experience);
	const markExperienceSuccess = useExperienceSuccess(experience);
	const markExperienceFail = useExperienceFail(experience);
	const abortExperience = useExperienceAbort(experience);

	const issueTypeOptions = useMemo(() => {
		const validIssueTypes: BusinessIssueType[] = issueTypes.filter((issueType) => {
			const isAllowed = allowedIssueTypeIds?.includes(issueType.id) ?? true;

			return (
				isAllowed &&
				issueType.hierarchyLevel >= minHierarchyLevel &&
				issueType.hierarchyLevel <= maxHierarchyLevel
			);
		});

		const typeOptions: IssueTypeOption[] = validIssueTypes.map((issueType) => ({
			label: issueType.name,
			value: issueType.id,
			issueType,
			optionType: ISSUE_TYPE,
		}));

		typeOptions.sort(
			// sort by decreasing hierarchy level
			(a, b) => b.issueType.hierarchyLevel - a.issueType.hierarchyLevel,
		);

		return typeOptions;
	}, [allowedIssueTypeIds, issueTypes, maxHierarchyLevel, minHierarchyLevel]);

	const options: OptionsOrGroups<
		SelectOption,
		GroupBase<LinkOption | ButtonOption>
	> = useMemo(() => {
		const canManageTypes = project.permissions.administerProject;
		const manageIssueTypesLink = getIssueTypesSettingsUrl(project.key, project.isSimplified);

		const [expConfig] = UNSAFE_noExposureExp('jsw_inline_issuetype_creation_list_view');
		if (expConfig.get('isInlineIssueTypesEnabled', false)) {
			if (canManageTypes) {
				const actionOptions = [];
				const createTypeOption: ButtonOption = {
					label: formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.createIssueTypesIssueTermRefresh
							: messages.createIssueTypes,
					),
					optionType: BUTTON,
				};
				actionOptions.push(createTypeOption);

				const manageTypesOption: LinkOption = {
					label: formatMessage(messages.manageIssueTypes),
					value: manageIssueTypesLink,
					url: manageIssueTypesLink,
					optionType: LINK,
				};

				actionOptions.push(manageTypesOption);
				return [...issueTypeOptions, { options: [...actionOptions] }];
			}
			return [...issueTypeOptions];
		}

		let manageTypesOption: LinkOption | null = null;

		if (canManageTypes) {
			manageTypesOption = {
				label: formatMessage(messages.manageIssueTypes),
				value: manageIssueTypesLink,
				url: manageIssueTypesLink,
				optionType: LINK,
			};

			return [...issueTypeOptions, manageTypesOption];
		}
		return [...issueTypeOptions];
	}, [formatMessage, issueTypeOptions, project]);

	const { summaryRef, submit, blur } = useCreateIssue<TIssue>(onSubmit, onBlur);
	const [selectedType, setSelectedType] = useState<IssueTypeOption>(() => {
		if (preferredIssueTypeId) {
			const defaultIssueType = issueTypeOptions.find(
				(issueType) => issueType.value === preferredIssueTypeId,
			);
			if (defaultIssueType) {
				return defaultIssueType;
			}
		}

		// Returns the first issue type with hierarchy level of zero if there is no last used issue type
		if (minHierarchyLevel === 0) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return (issueTypeOptions.find((option) => option.issueType.hierarchyLevel === 0) ??
				options[0]) as IssueTypeOption;
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return options[0] as IssueTypeOption;
	});
	const [textContent, setTextContent] = useState(summaryRef.current);
	summaryRef.current = textContent;

	const formRef = useRef<HTMLFormElement | null>(null);
	const popperRef = useRef<HTMLElement | null>(null);
	const isEmptyRef = useRef(true);
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	isEmptyRef.current = textContent.trim().length === 0;

	const handleCreateIssue = () => {
		if (!isEmptyRef.current && selectedType.optionType === ISSUE_TYPE) {
			const issueType = issueTypes.find((type) => type.id === selectedType.value);

			startExperience();

			const result = submit({
				input: {
					summary: textContent.trim(),
					projectId: String(project.id),
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					issueType: issueType!,
					...onPrepareIssueContext?.(),
				},
				fieldIdsToReturn,
				location,
				showSuccessFlag,
			});
			setTextContent('');

			result.promise
				.then(() => markExperienceSuccess())
				.catch((error) => {
					if (error instanceof CancelledError) {
						abortExperience('User cancelled');
					} else {
						markExperienceFail('Failed to create issue', error);
					}
				});
		}
	};
	const handleCreateIssueRef = useRef(handleCreateIssue);
	handleCreateIssueRef.current = handleCreateIssue;

	// prevent blurring from occurring when the form is first rendered
	const isReadyRef = useIsReady();

	const handleBlur = useCallback(
		(event: MouseEvent) => {
			if (!isReadyRef.current) {
				return;
			}

			// make sure to make a local copy of the isEmptyRef value before it gets reset by the create handler
			const formEmpty = isEmptyRef.current;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const inForm = formRef.current?.contains(event.target as Node) ?? false;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const inPopper = popperRef.current?.contains(event.target as Node) ?? false;

			if (!inForm && !inPopper) {
				handleCreateIssueRef.current();
				blur();
				fireUIAnalytics(createAnalyticsEvent({}), 'form blurred', 'inlineCreate', {
					location,
					formEmpty,
					escapeKeyPressed: false,
				});
			}
		},
		[isReadyRef, blur, createAnalyticsEvent, location],
	);

	const issueTypeButton = useCallback(
		// PopupSelectTriggerProps is not exported from @atlaskit/select
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(triggerProps: any) =>
			isVisualRefreshEnabled() ? (
				<Button
					{...triggerProps}
					appearance="subtle"
					aria-label={formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.selectIssueTypeButtonIssueTermRefresh
							: messages.selectIssueTypeButton,
						{
							issueType: selectedType.label,
						},
					)}
					testId="business-issue-create.ui.inline-create-form.issue-type-trigger"
					spacing="compact"
				>
					<Flex
						alignItems="center"
						justifyContent="center"
						gap="space.075"
						xcss={fg('jira_nav4_beta_drop_1') ? issueTypeButtonStyles : issueTypeButtonStylesOld}
					>
						<img
							src={selectedType.issueType.avatarUrl ?? undefined}
							alt=""
							height={16}
							width={16}
						/>
						<ChevronDownIcon />
					</Flex>
				</Button>
			) : (
				<StyledButton
					{...triggerProps}
					aria-label={formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.selectIssueTypeButtonIssueTermRefresh
							: messages.selectIssueTypeButton,
						{
							issueType: selectedType.label,
						},
					)}
					iconBefore={
						<img
							src={selectedType.issueType.avatarUrl ?? undefined}
							alt=""
							height={16}
							width={16}
						/>
					}
					iconAfter={
						isCompact ? (
							<ChevronDownWrapper>
								<ChevronDownIcon />
							</ChevronDownWrapper>
						) : (
							<ChevronDownIcon />
						)
					}
					spacing="compact"
					testId="business-issue-create.ui.inline-create-form.issue-type-trigger"
				>
					{!isCompact && selectedType.label}
				</StyledButton>
			),
		[formatMessage, isCompact, selectedType],
	);

	useEffect(() => {
		// on mount, we want to move the cursor to the end of the input
		textAreaRef.current?.setSelectionRange(
			textAreaRef.current.value.length,
			textAreaRef.current.value.length,
		);
		fireUIAnalytics(createAnalyticsEvent({}), 'form focused', 'inlineCreate', { location });
	}, [createAnalyticsEvent, location]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('click', handleBlur, true);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('click', handleBlur, true);
		};
	}, [handleBlur]);

	const issueTypesSelect = useMemo(
		() => (
			<PopupSelect
				onMenuOpen={() => {
					fireUIAnalytics(createAnalyticsEvent({}), 'select opened', 'inlineCreateIssueTypes', {
						location,
					});
				}}
				components={{
					Option: expVal(
						'jsw_inline_issuetype_creation_list_view',
						'isInlineIssueTypesEnabled',
						false,
					)
						? Option
						: OptionOld,
					Group,
				}}
				searchThreshold={10}
				options={options}
				onChange={(option) => {
					if (option?.optionType === ISSUE_TYPE) {
						setSelectedType(option);
						savePreferredIssueTypeId(option.value);
					}
				}}
				popperProps={{ strategy: popperStrategy, innerRef: popperRef }}
				value={selectedType}
				target={({ isOpen, ...triggerProps }) =>
					isCompact || (appearance === ROW_APPEARANCE && isVisualRefreshEnabled()) ? (
						<Tooltip content={selectedType.label}>{issueTypeButton(triggerProps)}</Tooltip>
					) : (
						issueTypeButton(triggerProps)
					)
				}
			/>
		),
		[
			appearance,
			createAnalyticsEvent,
			isCompact,
			issueTypeButton,
			location,
			options,
			popperStrategy,
			savePreferredIssueTypeId,
			selectedType,
		],
	);

	const getRowHeader = useCallback(() => {
		if (isVisualRefreshEnabled()) {
			return issueTypesSelect;
		}
		if (!isCompact) {
			return <IssueTypeIcon alt="" src={selectedType.issueType.avatarUrl ?? GENERIC_ICON_URL} />;
		}
		return null;
	}, [isCompact, issueTypesSelect, selectedType]);

	// this is not a scenario we expect to happen but we know it does happen when a project is just created
	// the issue types and fields have a retry mechanism to avoid this but we want to handle it here as well
	// to avoid runtime errors
	if (!fg('fun-1256_handle_available_fields_load_in_ic_2') && issueTypes.length === 0) {
		return null;
	}

	return (
		<FullContent
			appearance={appearance}
			borderRadius={borderRadius}
			indent={indent}
			minHeight={minHeight}
			onKeyDown={(event) => {
				event.stopPropagation();

				if (event.key === 'Escape' && !isComposing(event)) {
					if (fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')) {
						blur(event);
					} else {
						blur();
					}
					fireUIAnalytics(createAnalyticsEvent({}), 'form blurred', 'inlineCreate', {
						location,
						formEmpty: isEmptyRef.current,
						escapeKeyPressed: true,
					});
				}
			}}
			onSubmit={(event) => {
				event.preventDefault();
				handleCreateIssue();
			}}
			ref={formRef}
		>
			{appearance === ROW_APPEARANCE && getRowHeader()}
			<StyledTextArea
				appearance={appearance}
				autoFocus
				data-testid="business-issue-create.ui.inline-create-form.textarea"
				maxLength={MAX_SUMMARY_LENGTH}
				onChange={(event) => {
					const content = event.target.value.replace(/[\n\r]+/g, ' ');
					setTextContent(content);
				}}
				onKeyDown={(event) => {
					if (event.key === 'Enter' && !isComposing(event)) {
						event.preventDefault();
						handleCreateIssue();
					}
				}}
				placeholder={formatMessage(messages.placeholderText)}
				value={textContent}
				ref={textAreaRef}
			/>
			<Footer appearance={appearance}>
				{(!isVisualRefreshEnabled() || appearance !== ROW_APPEARANCE) && issueTypesSelect}
				{appearance !== ROW_APPEARANCE && isVisualRefreshEnabled() ? (
					<IconButton
						label={formatMessage(messages.createButton)}
						appearance="default"
						type="submit"
						onClick={() => {
							fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'inlineCreateSubmit', {
								location,
								hierarchyLevel:
									selectedType.optionType === ISSUE_TYPE
										? selectedType.issueType?.hierarchyLevel
										: undefined,
							});
						}}
						isDisabled={isEmptyRef.current}
						spacing="compact"
						icon={() => (
							<Text color="inherit" weight="medium">
								⏎
							</Text>
						)}
					/>
				) : (
					<StyledCreateButton
						appearance="primary"
						iconAfter={<ReturnIcon />}
						isDisabled={isEmptyRef.current}
						spacing="compact"
						type="submit"
						onClick={() => {
							fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'inlineCreateSubmit', {
								location,
								hierarchyLevel:
									selectedType.optionType === ISSUE_TYPE
										? selectedType.issueType?.hierarchyLevel
										: undefined,
							});
						}}
					>
						{formatMessage(messages.createButton)}
					</StyledCreateButton>
				)}
			</Footer>
		</FullContent>
	);
};

const emptyArray: BusinessIssueType[] = [];

const InlineCreateFormWithErrorBoundary = <TIssue,>(props: Props<TIssue>) => {
	const experience = useExperience(props.location);

	return (
		<ExperienceErrorBoundary experience={experience} fallback="flag">
			{fg('fun-1256_handle_available_fields_load_in_ic_2') ? (
				<InlineCreateForm<TIssue> {...props} />
			) : (
				<InlineCreateFormInternal<TIssue> {...props} issueTypes={emptyArray} />
			)}
		</ExperienceErrorBoundary>
	);
};

const genericMemo: <T>(component: T) => T = memo;

export default genericMemo(InlineCreateFormWithErrorBoundary);

type AppearanceProps = InlineCreateStyles & { appearance: InlineCreateAppearance };
const lineHeight = 20;

// margin: '0px' is needed to override the default margin of the form element
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullContent = styled.form<AppearanceProps>(
	{
		display: 'flex',
		gap: token('space.100', '8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface', colors.N0),
		transition: 'box-shadow 200ms ease-in-out',
		boxShadow: `inset 0 0 0 ${token('border.width.outline', '2px')} ${token(
			'color.border.focused',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			colors.B200,
		)}`,
		boxSizing: 'border-box',
		outline: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderRadius: ({ borderRadius }) => borderRadius ?? token('border.radius', '3px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		minHeight: ({ minHeight }) => minHeight ?? '40px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		flexDirection: (props) => (props.appearance === CARD_APPEARANCE ? 'column' : 'row'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		alignItems: (props) => props.appearance === ROW_APPEARANCE && 'center',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: (props) => (props.appearance === ROW_APPEARANCE ? '40px' : '100px'),
		margin: '0px',
		paddingTop: token('space.050', '4px'),
		paddingRight: token('space.100', '8px'),
		paddingBottom: token('space.050', '4px'),
		paddingLeft: token('space.100', '8px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.appearance === CARD_APPEARANCE && {
			paddingTop: token('space.150', '12px'),
			paddingRight: token('space.150', '12px'),
			paddingBottom: token('space.150', '12px'),
			paddingLeft: token('space.150', '12px'),
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.indent && {
			paddingLeft: props.indent,
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledTextArea = styled.textarea<AppearanceProps>(
	{
		flex: '1',
		border: 'none',
		outline: 'none',
		margin: 0,
		padding: token('space.050', '4px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: (props) =>
			props.appearance === CARD_APPEARANCE ? `${lineHeight * 2}px` : `${lineHeight}px`,
		resize: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface', colors.N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N800),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		fontFamily,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		fontSize: `${fontSize}px`,
		lineHeight: `${lineHeight}px`,
		'&::placeholder': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: token('color.text.subtlest', colors.N200),
			opacity: 1,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.appearance === ROW_APPEARANCE &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			whiteSpace: 'pre',
			scrollbarWidth: 'none',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&::-webkit-scrollbar': {
				display: 'none',
			},
		}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div<AppearanceProps>({
	display: 'flex',
	gap: token('space.100', '8px'),
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: (props) => (props.appearance === CARD_APPEARANCE ? 'space-between' : 'end'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(ButtonLegacy)({
	minWidth: '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledCreateButton = styled(ButtonLegacy)({
	flex: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionTypeIcon = styled.img({
	height: token('space.200', '16px'),
	width: token('space.200', '16px'),
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExtraLinksGroup = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `2px solid ${token('color.border', colors.N30)}`,
	marginTop: token('space.050', '4px'),
	paddingTop: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLink = styled(Link)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		textDecoration: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueTypeIcon = styled.img({
	height: token('space.200', '16px'),
	width: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ChevronDownWrapper = styled.div({
	margin: `0 ${token('space.negative.100', '-8px')} 0 ${token('space.negative.050', '-4px')}`,
});

const issueTypeButtonStylesOld = xcss({
	marginTop: 'space.050',
});

const issueTypeButtonStyles = xcss({
	marginTop: 'space.025',
});

const spinnerWrapperStyles = xcss({
	flex: 1,
});
